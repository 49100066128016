import { supa } from '../components/firebase';
import { useEffect, useState } from 'react';
import { Search, Plus, Minus, PageEdit } from 'iconoir-react';
import ModalPersonal from '../components/modalPersonal';
import ModalLezione from '../components/modalLezione';
import ModalInfoPacchetto from '../components/modalInfoPacchetto';
import ActivityIndicator from '../components/activityIndicator';

function Pilates() {
  const [modalAdd, setModalAdd] = useState(false)
  const [modalLezione, setModalLezione] = useState(false)
  const [modalInfoPacchetto, setModalInfoPacchetto] = useState(false)

  const [pacchetti, setPacchetti] = useState([])
  const [search, setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedPachettoPersonal, setSelectedPacchettoPersonal] = useState(null)

  const [loading, setLoading] = useState(true)

  const openModal = (users, pacchettoPersonal) => {
    setSelectedUser(users)
    setSelectedPacchettoPersonal(pacchettoPersonal)
    setModalLezione(true)
  }

  const openModalInfoPacchetto = (user, pacchetto) => {
    setSelectedUser(user)
    setSelectedPacchettoPersonal(pacchetto)
    setModalInfoPacchetto(true)
  }


  async function getRows() {
    setLoading(true)
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .select('*,utenti(*),pacchetti(*)')
      .eq('tipo', 1)
    if (error) console.log("---", error); setLoading(false)
      data.sort((a, b) => a.utenti.nome.localeCompare(b.utenti.nome) || a.utenti.cognome.localeCompare(b.utenti.cognome))
      setPacchetti(data)
      setLoading(false)
  }

  async function patchRow(id) {
    setLoading(true)
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .update({ "completato": !pacchetti.find((item) => item.id === id).completato })
      .eq('id', id)
    if (error) console.log(error); setLoading(false)
    getRows()
    setLoading(false)
  }

  function alertPatch(id) {
    if (window.confirm(`Sei sicuro di voler segnare come ${!pacchetti.find((item) => item.id === id).completato ? "completato" : "non completato"}?`)) {
      patchRow(id)
    }
  }


  useEffect(() => {
    getRows()
  }
    , [])

  return (
    <div className="flex flex-1 h-[calc(100vh-4.5rem)] flex-col bg-zinc-100 items-center px-10 pb-10 justify-center">
            {loading ? <ActivityIndicator /> : <>
      {modalAdd && <ModalPersonal visible={modalAdd} setVisible={setModalAdd} aggiorna={getRows} pilates={true} />}
      {modalLezione && <ModalLezione visible={modalLezione} setVisible={setModalLezione} aggiorna={getRows} utente={selectedUser} pacchetto={selectedPachettoPersonal} />}
      {modalInfoPacchetto && <ModalInfoPacchetto visible={modalInfoPacchetto} setVisible={setModalInfoPacchetto} aggiorna={getRows} utente={selectedUser} pacchetto={selectedPachettoPersonal} />}
      <div className='h-1/6 w-full pb-2'>
        <div className='text-4xl text-zinc-800 font-bold mt-4'>Lista Pacchetti Personal Acquistati</div>
        <div className='w-full h-1/2 flex items-center justify-between'>
          <div>
            <div className='h-10 w-96 rounded-xl bg-white  shadow-zinc-200 px-2 flex items-center'>
              <Search size={20} color='black' />
              <input type='text' placeholder='Cerca utente' className='w-full h-full ml-2 bg-transparent outline-none' onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>
          <button onClick={() => setModalAdd(true)} type="submit" className="shadow-lg text-zinc-800 inline-flex items-center bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center">
            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
            Aggiungi acquisto
          </button>
        </div>


      </div>
      <div className='flex overflow-hidden w-full h-5/6'>
      <div className="overflow-hidden w-full h-full rounded-3xl">
        <table className="text-left w-full bg-white shadow-lg shadow-zinc-200 h-full">
        <thead className="bg-white py-2 flex border-b border-yellow-400 text-gray-600 w-full">
        <tr className="flex w-full">
                <th className="p-4 w-1/4">Nome</th>
                <th className="p-4 w-1/4">Cognome</th>
                <th className="p-4 w-2/4">Pacchetto</th>
                <th className="p-4 w-1/4">Inizio</th>
                <th className="p-4 w-1/4">Fine</th>
                <th className="p-4 w-1/5">Totale</th>
                <th className="p-4 w-1/5">Acconto</th>
                <th className="p-4 w-1/5">Rimanente</th>
                <th className="p-4 w-1/4">Sed. pacchetto</th>
                <th className="p-4 w-1/3">Sed. effettuate</th>
                <th className="p-4 w-1/5">Completato</th>
                <th className="p-4 w-1/5">Azioni</th>
              </tr>
            </thead>
            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-28 w-full h-full" >
              {pacchetti?.filter((item) => item?.utenti?.nome?.toLowerCase()?.includes(search?.toLowerCase()) || item?.utenti?.cognome?.toLowerCase()?.includes(search?.toLowerCase())).map((item, index) =>               <tr key={index} className="flex w-full hover:bg-zinc-100 py-1 bg-white">

                <td className="p-4 w-1/4">{item?.utenti?.nome}</td>
                <td className="p-4 w-1/4">{item?.utenti?.cognome}</td>
                <td className="p-4 w-2/4">{item?.pacchetti?.nome}, {item?.totale} €</td>
                <td className="p-4 w-1/4">{item?.inizio ? new Date(item?.inizio).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/4">{item?.fine ? new Date(item?.fine).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/5">{item?.totale} €</td>
                <td className="p-4 w-1/5">{item?.acconto} €</td>
                <td className="p-4 w-1/5">{item?.totale - item?.acconto} €</td>
                <td className="p-4 w-1/4">{item?.pacchetti?.num_lezioni}</td>
                <td className="p-4 w-1/3 flex items-start">
                  <div className='flex w-1/2 items-center justify-between' >
                    {item?.sedute_effettuate}
                    <div onClick={()=> openModal(item?.utenti, item)} className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center">
                      <Plus size={20} color='black' />
                    </div>
                    <div className="w-6 h-6 invisible cursor-pointer rounded-full bg-red-400 flex items-center justify-center">
                      <Minus size={20} color='white' />
                    </div>
                  </div>
                </td>
                <td className="p-4 w-1/5">
                  <input type="checkbox" checked={item?.completato} onChange={() => alertPatch(item.id)}  className='w-4 h-4 accent-yellow-400 cursor-pointer text-red-600 border-gray-300 rounded-xl focus:ring-yellow-500 focus:ring-2'/>
                </td>
                <td className="p-4 w-1/5">
                    <PageEdit onClick={()=> openModalInfoPacchetto(item?.utenti, item)} className=' cursor-pointer' size={20} color='black' />
                </td>
              </tr>)}
            </tbody>
          </table>

        </div>
      </div></>}
    </div>
  );
}

export default Pilates;
