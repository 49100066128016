import { useState, useEffect } from "react";
import { supa } from "./firebase";

function ModalPersonal({ visible, setVisible, aggiorna, pilates }) {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [notes, setNotes] = useState('');
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [pacchetti, setPacchetti] = useState([])
    const [selectedPacchetto, setSelectedPacchetto] = useState(null)
    const [inizio, setInizio] = useState(new Date());
    const [fine, setFine] = useState(new Date());
    const [totale, setTotale] = useState(0);
    const [acconto, setAcconto] = useState(0);
    const [filter, setFilter] = useState('')
    const [selectVisible, setSelectVisible] = useState(false)

    const setVisibleSelect = (value) => {
        setSelectVisible(value)
    }

    async function addRow() {
        if (!selectedUser || !selectedPacchetto || !totale) {
            alert('Inserire tutti i campi')
            return
        }
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .insert({ "id_utente": selectedUser, "id_pacchetto": selectedPacchetto, "inizio": inizio, "fine": fine, "totale": totale, "acconto": acconto, "note": notes, "tipo": pilates ? 1 : 0 })
            .select()
        if (error) console.log(error)
            if (acconto > 0){
        const { data2, error2 } = await supa
            .from('versamenti')
            .insert({ "id_utente": selectedUser, "id_pacchettiAcquistati": data[0].id, "data": new Date(), "importo": acconto })
        if (error2) console.log(error2)
        }
        aggiorna()
        setVisible(false)
    }

    async function getUtenti() {
        const { data, error } = await supa
            .from('utenti')
            .select('*')
        if (error) console.log(error)
        data.sort((a, b) => a.nome.localeCompare(b.nome) || a.cognome.localeCompare(b.cognome))
        if (data.length > 0) setSelectedUser(data[0].id)
        setUsers(data)
    }

    async function getPacchetti() {
        const { data, error } = await supa
            .from('pacchetti')
            .select('*')
            .eq('tipo', pilates ? 1 : 0)
        if (error) console.log(error)
        if (data.length > 0) setSelectedPacchetto(data[0].id)
        setPacchetti(data)
    }

    useEffect(() => {
        getUtenti()
        getPacchetti()
    }
        , [])

    useEffect(() => {
        setTotale(pacchetti.find(item => item.id == selectedPacchetto)?.prezzo)
    }, [selectedPacchetto])


    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">
            {users.length > 0 && pacchetti.length > 0 &&
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-xl shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-lg font-semibold text-gray-900">
                                Nuovo Acquisto Pacchetto
                            </h3>
                            <div onClick={() => setVisible(false)} className="text-gray-400 bg-transparent cursor-pointer hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </div>
                        </div>
                        <form className="p-4 md:p-5">
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2 ">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Utente</label>
                                    <div className="relative group">
                                        <div onClick={() => setSelectVisible(!selectVisible)} className="bg-gray-50 cursor-pointer border flex border-gray-300 text-gray-900 text-sm justify-between rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                            <span className="mr-2">{users.find(item => item.id == selectedUser)?.nome} {users.find(item => item.id == selectedUser)?.cognome} ( {users.find(item => item.id == selectedUser)?.birth ? new Date(users.find(item => item.id == selectedUser)?.birth).toLocaleDateString('it-IT') : ""} )</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        {selectVisible && <div className="absolute noBar h-44 overflow-scroll right-0 w-full mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1">
                                            <input onChange={(e) => setFilter(e.target.value)} className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none" type="text" placeholder="Cerca utente" />
                                            {users.filter((item) => item.nome.toLowerCase().includes(filter.toLowerCase()) || item.cognome.toLowerCase().includes(filter.toLowerCase())).map((item, index) => <button onClick={() => { setSelectedUser(item.id); setSelectVisible(false) }} key={index} className="w-full px-4 py-2 text-gray-800 items-start flex hover:bg-gray-100   border-gray-300 focus:outline-none" type="text" placeholder="Search items" autocomplete="off">{item.nome} {item.cognome} ( {item.birth ? new Date(item.birth).toLocaleDateString('it-IT') : ""} )</button>)}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-span-2 ">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pacchetto</label>
                                    <select onChange={(e) => { setSelectedPacchetto(parseInt(e.target.value)) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        {pacchetti.map((item, index) => <option key={index} value={item.id}>{item.nome} - {item.num_lezioni} lezioni - {item?.prezzo} €</option>)}
                                    </select>
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Inizio</label>
                                    <input type="date" onChange={(e) => setInizio(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fine</label>
                                    <input type="date" onChange={(e) => setFine(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Totale</label>
                                    <input type="number" value={totale} onChange={(e) => setTotale(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                                </div>

                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Acconto</label>
                                    <input type="number" onChange={(e) => setAcconto(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Acconto solo numeri" required="" />
                                </div>
                                <div className="col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Eventuali Note</label>
                                    <textarea onChange={(e) => setNotes(e.target.value)} name="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5 " placeholder="Inserisci note" required=""></textarea>
                                </div>
                            </div>
                            <div onClick={() => addRow()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                Aggiungi acquisto
                            </div>
                        </form>
                    </div>
                </div>
            }

        </div>
    );
}

export default ModalPersonal;
