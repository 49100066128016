import { useState } from "react";
import { supa } from "./firebase";

function ModalPersonalTrainer({ visible, setVisible, aggiorna }) {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birth, setBirth] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [retribuzione, setRetribuzione] = useState(0);

    async function addRow() {
        const { data, error } = await supa
            .from('personalTrainer')
            .insert({ "nome": name, "cognome": surname, "birth": birth, "note": notes, "costo": retribuzione })
        if (error) console.log(error)
        aggiorna()
        setVisible(false)
    }

    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">

            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Nuovo Personal Trainer
                        </h3>
                        <button onClick={() => setVisible(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Nome</label>
                                <input type="text" onChange={(e) => setName(e.target.value)} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci nome" required="" />
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Cognome</label>
                                <input type="text" onChange={(e) => setSurname(e.target.value)} name="surname" id="surname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci cognome" required="" />
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                                <label  className="block mb-2 text-sm font-medium text-gray-900 ">Data di Nascita</label>
                                <input type="date" onChange={(e) => setBirth(e.target.value)} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                            </div>
                            <div className="col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Costo</label>
                                <input type="number" onChange={(e) => setRetribuzione(e.target.value)} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci costo" required="" />
                            </div>
                            {/*<div className="col-span-2 sm:col-span-1">
                                <labelhtmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                                <select id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    <option selected="">Select category</option>
                                    <option value="TV">TV/Monitors</option>
                                    <option value="PC">PC</option>
                                    <option value="GA">Gaming/Console</option>
                                    <option value="PH">Phones</option>
                                </select>
                            </div>*/}
                            <div className="col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Eventuali Note</label>
                                <textarea onChange={(e) => setNotes(e.target.value)} name="description" id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5 " placeholder="Inserisci note" required=""></textarea>
                            </div>
                        </div>
                        <div onClick={() => addRow()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Aggiungi personal trainer
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default ModalPersonalTrainer;
