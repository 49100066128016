import { useState, useEffect } from "react";
import { supa } from "./firebase";

function ModalConsulenza({ setVisible, aggiorna, utente}) {
    const [notes, setNotes] = useState('');
    const [selectedUser, setSelectedUser] = useState(null)
    const [pacchetti, setPacchetti] = useState([])
    const [selectedPacchetto, setSelectedPacchetto] = useState(null)
    const [inizio, setInizio] = useState(new Date().toISOString().split('T')[0])
    const [fine, setFine] = useState(new Date().toISOString().split('T')[0])
    const [totale, setTotale] = useState(0);
    const [listaPersonalTrainers, setListaPersonalTrainers] = useState([])

    const [selectedPersonalTrainer, setSelectedPersonalTrainer] = useState(null)

    async function addRow() {
        if (!utente?.id || !selectedPacchetto || !totale) {
            alert('Inserire tutti i campi')
            return
        }
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .insert({ "id_utente": utente?.id, "id_pacchetto": selectedPacchetto, "inizio": inizio, "fine": fine, "totale": totale, "acconto": totale, "note": notes, "tipo": 2, completato: true })
            .select()
        if (error) console.log(error)
            const { data2, error2 } = await supa
            .from('versamenti')
            .insert({ "id_utente": utente?.id, "id_pacchettiAcquistati": data[0].id, "data": new Date(), "importo": totale })
        aggiorna()
        setVisible(false)
    }

    async function getPacchetti() {
        const { data, error } = await supa
            .from('pacchetti')
            .select('*')
            .eq('tipo', 2)
        if (error) console.log(error)
        if (data.length > 0) setSelectedPacchetto(data[0].id)
        setPacchetti(data)
    }

    async function getPersonalTrainers() {
        const { data, error } = await supa
            .from('personalTrainer')
            .select('*')
        if (error) console.log(error)
            setListaPersonalTrainers(data)
            setSelectedPersonalTrainer(data[0].id)
    }

    useEffect(() => {
        getPacchetti()
        getPersonalTrainers()
    }
        , [])

    useEffect(() => {
        setTotale(pacchetti.find(item => item.id == selectedPacchetto)?.prezzo)
    }, [selectedPacchetto])


    
    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">

            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Acquisto Consulenza
                        </h3>
                        <div onClick={() => setVisible(false)} className="text-gray-400 bg-transparent cursor-pointer hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </div>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2 ">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Utente</label>
                                <div className="relative group">
                                    <div  className="bg-gray-50  border flex border-gray-300 text-gray-900 text-sm justify-between rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <span className="mr-2">{utente?.nome} {utente?.cognome}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 ">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pacchetto</label>
                                <select onChange={(e) => { setSelectedPacchetto(parseInt(e.target.value)) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {pacchetti?.map((item, index) => <option key={index} value={item.id}>{item.nome} - {item?.prezzo} €</option>)}
                                </select>
                            </div>
                            <div className="col-span-2 ">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Effettuata con</label>
                                <select onChange={(e) => { setSelectedPersonalTrainer(parseInt(e.target.value)) }} value={selectedPersonalTrainer} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {listaPersonalTrainers?.map((item, index) => <option key={index} value={item.id}>{item.nome} {item?.cognome}</option>)}
                                </select>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Data</label>
                                <input type="date" onChange={(e) => setInizio(e.target.value)} value={inizio} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Totale</label>
                                <input type="number" value={totale} onChange={(e) => setTotale(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                            </div>

                            {/*<div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Acconto</label>
                                <input type="number" onChange={(e) => setAcconto(e.target.value)} name="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
    </div>*/}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Eventuali Note</label>
                                <textarea onChange={(e) => setNotes(e.target.value)} name="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5 " placeholder="Inserisci note" required=""></textarea>
                            </div>
                        </div>
                        <div onClick={() => addRow()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Aggiungi consulenza
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default ModalConsulenza;
