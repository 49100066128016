import { useState, useEffect, useMemo } from "react";
import { supa } from "../components/firebase";
import { Plus, Minus, Search, NavArrowLeft, NavArrowRight, Trash } from 'iconoir-react'
import { useParams, useNavigate } from "react-router-dom";
import ModalConsulenza from "../components/modalConsulenza";
import ModalProdotto from "../components/modalProdotto";
import ModalSelezionaProdotto from "../components/modalSelezioneProdotto";

function Bar() {
  const [selectedData, setData] = useState(new Date().toISOString().split('T')[0])

  const [prodotti, setProdotti] = useState([])
  const [modalProdottoVisible, setModalProdottoVisible] = useState(false)
  const [prodottiVenduti, setProdottiVenduti] = useState([])
  const [search, setSearch] = useState('')
  const [modalSelezionaProdotto, setModalSelezionaProdotto] = useState(false)

  async function getProdotti() {
    //select * from prodotti include venditeProdotti where data == dal
    const { data, error } = await supa
      .from('prodotti')
      .select("*")
    if (error) console.log(error)
    setProdotti(data)
  }

  async function getProdottiVenduti() {
    const { data, error } = await supa
      .from('venditeProdotti')
      .select("*, prodotti(*)")
      .eq('data', selectedData)
    if (error) console.log(error)
      data.sort((a, b) => a.prodotti.prodotto.localeCompare(b.prodotti.prodotto))
    setProdottiVenduti(data)
  }

  useEffect(() => {
    getProdotti()
  }
    , [])

  useEffect(() => {
    getProdotti()
    getProdottiVenduti()
  }
    , [selectedData])

  function refresh() {
    getProdotti()
    getProdottiVenduti()
  }

  const patchRow = async (id, tipo, contatore, piuOmeno) => {
    if(contatore <= 0 && !piuOmeno) {alert('Sono già zero... non posso decrementare di piu'); return}
    const chi = tipo == 1 ? "contatore_clienti" : tipo == 2 ? "contatore_staff" : "contatore_proprietari"
    const { data, error } = await supa
      .from('venditeProdotti')
      .update({ [chi]: piuOmeno ? contatore + 1 : contatore - 1 })
      .eq('id', id)
    if (error) console.log(error)
    getProdottiVenduti()
  }

  const deleteProdottiVenduti = async (id) => {
    const { data, error } = await supa
      .from('venditeProdotti')
      .delete()
      .eq('id', id)
    if (error) console.log(error)
    getProdottiVenduti()
  }

  function alertDelete(nome,id) {
    if (window.confirm(`Sei sicuro di voler eliminare il prodotto ${nome}?`)) {
      deleteProdottiVenduti(id)
    }
  }


  return (
    <div className="flex flex-1 h-[calc(100vh-4.5rem)] bg-zinc-100 flex-col items-center px-10 pb-10">
      {modalProdottoVisible && <ModalProdotto visible={modalProdottoVisible} setVisible={setModalProdottoVisible} aggiorna={refresh} />}
      {modalSelezionaProdotto && <ModalSelezionaProdotto visible={modalSelezionaProdotto} setVisible={setModalSelezionaProdotto} aggiorna={refresh} selectedData={selectedData} prodotti={prodotti.filter(item => prodottiVenduti.find(item2 => item2.id_prodotto === item.id) === undefined)} />}
      <div className='h-1/3 w-full flex'>
        <div className='flex flex-col h-full w-1/2'>
          <div className='h-20 flex items-center justify-between'>
          <div className='text-4xl text-zinc-800 font-bold'>Gestione Prodotti</div>
            <div className='flex items-center w-64 justify-between'>
              <NavArrowLeft className="cursor-pointer" size={30} color='black' onClick={() => setData(new Date(new Date(selectedData).getTime() - 86400000).toISOString().split('T')[0])} />
              <div className="col-span-2 sm:col-span-1">
                <input type="date" value={selectedData} onChange={(e) => setData(e.target.value)} name="price" id="price" className="  inline-flex items-center text-zinc-800 bg-yellow-400 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center"  placeholder="Inserisci data di nascita" required="" />
              </div>
              <NavArrowRight className="cursor-pointer" size={30} color='black' onClick={() => setData(new Date(new Date(selectedData).getTime() + 86400000).toISOString().split('T')[0])} />
            </div>

          </div>
          <div className='h-full flex items-center justify-between'>
            <div className='h-full w-1/2 rounded-3xl flex items-start justify-center flex-col bg-zinc-800 p-6 shadow-lg '>
                <p className='text-4xl text-white font-semibold'>Incasso Totale</p>
                <p className='text-6xl text-yellow-400 font-bold'> {prodottiVenduti.reduce((acc, item) => acc + (item.prodotti.prezzo_clienti * item.contatore_clienti) + (item.prodotti.prezzo_staff * item.contatore_staff), 0).toFixed(2)} €</p>
            </div>
            <div className='h-full w-1/2 rounded-3xl ml-10 flex items-start justify-center flex-col bg-zinc-800 p-6 shadow-lg '>
                <p className='text-4xl text-white font-semibold'>Guadagno Totale</p>
                <p className='text-6xl text-yellow-400 font-bold'> {prodottiVenduti.reduce((acc, item) => acc + (item.prodotti.prezzo_clienti * item.contatore_clienti) + (item.prodotti.prezzo_staff * item.contatore_staff) - (item.prodotti.costo * item.contatore_proprietari) - (item.prodotti.costo * (item.contatore_clienti + item.contatore_staff)), 0).toFixed(2)} €</p>
            </div>

          </div>
        </div>


        <div className='flex flex-col  h-full ml-10 w-1/2'>
          <div className='h-20 flex items-center justify-between'>
            <div className='w-full flex items-center font-semibold text-2xl'>Prodotti</div>
            <button onClick={() => setModalProdottoVisible(true)} type="submit" className=" w-52 inline-flex items-center bg-yellow-400 text-zinc-800 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
              <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
              Nuovo prodotto
            </button>

            
          </div>
          <div className="overflow-hidden w-full h-full rounded-3xl pb-10 shadow-lg ">
          <table className="text-left w-full h-full">
          <thead className="bg-white flex border-b border-yellow-400 text-gray-600 w-full">
                <tr className="flex w-full bg-white">
                  <th className="p-4 w-3/4">Prodotto Cognome</th>
                  <th className="p-4 w-2/4">Costo</th>
                  <th className="p-4 w-2/4">Prezzo Staff</th>
                  <th className="p-4 w-2/4">Prezzo Clienti</th>
                </tr>
              </thead>
              <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
              {prodotti?.map((item, index) => <tr key={index} className="flex w-full bg-white ">
                  <td className="p-4 w-3/4">{item?.prodotto}</td>
                  <td className="p-4 w-2/4">{item?.costo} €</td>
                  <td className="p-4 w-2/4">{item?.prezzo_staff} €</td>
                  <td className="p-4 w-2/4">{item?.prezzo_clienti} €</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='h-1/6 w-full pb-2'>
        <div className='w-full h-1/2 flex font-semibold items-center text-2xl'>Lista Prodotti Venduti in data {new Date(selectedData).toLocaleDateString()}</div>
        <div className='w-full h-1/2 flex items-center justify-between'>
          <div>
            <div className='h-10 w-96 rounded-xl bg-white px-2 flex items-center'>
              <Search size={20} color='black' />
              <input type='text' placeholder='Cerca tra i prodotti venduti' className='w-full h-full ml-2 bg-transparent outline-none' onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>

          <button onClick={() => setModalSelezionaProdotto(true)} type="submit" className="  inline-flex items-center bg-yellow-400 text-zinc-800 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
              <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
              Prodotto
            </button>
        </div>


      </div>
      <div className='h-3/4 w-full flex items-center justify-between pb-2'>
        <div className="overflow-hidden w-full h-full rounded-3xl bg-white">
          <table className="text-left w-full h-full">
            <thead className="bg-white flex border-b border-b-yellow-400 text-gray-600 w-full">
              <tr className="flex w-full bg-white">
                <th className="p-4 w-2/4">Prodotto</th>
                <th className="p-4 w-2/4">Costo</th>
                <th className="p-4 w-2/4">Prezzo clienti</th>
                <th className="p-4 w-2/4">Prezzo staff</th>
                <th className="p-4 w-2/4">Contatore clienti</th>
                <th className="p-4 w-2/4">Contatore staff</th>
                <th className="p-4 w-2/4">Contatore proprietari</th>
                <th className="p-4 w-1/4">Totale costo</th>
                <th className="p-4 w-1/4">Totale guadagno</th>
                <th className="p-4 w-1/4">Elimina</th>
              </tr>
            </thead>
            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-20 w-full h-full" >
              {prodottiVenduti?.map((item, index) => <tr key={index} className="flex w-full bg-white">
                <td className="p-4 w-2/4">{item?.prodotti?.prodotto}</td>
                <td className="p-4 w-2/4">{item?.prodotti?.costo}</td>
                <td className="p-4 w-2/4">{item?.prodotti?.prezzo_clienti}</td>
                <td className="p-4 w-2/4">{item?.prodotti?.prezzo_staff}</td>
                <td className="p-4 w-2/4 flex items-start">
                  <div className='flex w-1/2 items-center justify-between' >
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-red-400 flex items-center justify-center" onClick={() => patchRow(item.id, 1, item.contatore_clienti, false)}>
                      <Minus size={20} color='white' />
                    </div>
                    {item?.contatore_clienti}
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center" onClick={() => patchRow(item.id, 1, item.contatore_clienti, true)}>
                      <Plus size={20} color='black' />
                    </div>
                  </div>
                </td>
                <td className="p-4 w-2/4 flex items-start">
                  <div className='flex w-1/2 items-center justify-between' >
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-red-400 flex items-center justify-center" onClick={() => patchRow(item.id, 2, item.contatore_staff, false)}>
                      <Minus size={20} color='white' />
                    </div>
                    {item?.contatore_staff}
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center" onClick={() => patchRow(item.id, 2, item.contatore_staff, true)}>
                      <Plus size={20} color='black' />
                    </div>
                  </div>
                </td>
                <td className="p-4 w-2/4 flex items-start">
                  <div className='flex w-1/2 items-center justify-between' >
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-red-400 flex items-center justify-center" onClick={() => patchRow(item.id, 3, item.contatore_proprietari, false)}>
                      <Minus size={20} color='white' />
                    </div>
                    {item?.contatore_proprietari}
                    <div className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center" onClick={() => patchRow(item.id, 3, item.contatore_proprietari, true)}>
                      <Plus size={20} color='black' />
                    </div>
                  </div>
                </td>
                <td className="p-4  w-1/4">{(parseFloat(item?.prodotti?.costo) * (parseFloat(item?.contatore_clienti) + parseFloat(parseFloat(item?.contatore_staff) + parseFloat(item?.contatore_proprietari)))).toFixed(2)} €</td>
                {/*<td className="p-4  w-1/4">{item?.prodotti?.prezzo_clienti * item?.contatore_clienti} + {item?.prodotti?.prezzo_staff * item?.contatore_staff} - {item?.prodotti?.costo * item?.contatore_proprietari} €</td>*/}

                <td className="p-4  w-1/4">{(parseFloat(item?.prodotti?.prezzo_clienti * item?.contatore_clienti) + parseFloat(item?.prodotti?.prezzo_staff * item?.contatore_staff) - parseFloat(item?.prodotti?.costo * item?.contatore_proprietari) - (item?.prodotti?.costo * (item?.contatore_clienti + item?.contatore_staff))).toFixed(2)} €</td>
                <th className="p-4 w-1/4 cursor-pointer" onClick={() => alertDelete(item?.prodotti?.prodotto,item.id)}><Trash size={20} color='black' /></th>
              </tr>)}
            </tbody>
          </table>

        </div>
      </div>


    </div>
  );
}

export default Bar;
