import { Session, User } from '@supabase/supabase-js';
import { createContext, useState, useEffect, useContext } from 'react';
import { supa } from './firebase';
import { Navigate } from 'react-router-dom';

const AuthContext = createContext({
  user: null
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { data: listener } = supa.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        setUser(session?.user || null);
        setLoading(false);
      }
    );
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  // In case we want to manually trigger a signIn (instead of using Auth UI)
  const signIn = async () => {
    const { data, error } = await supa.auth.signInWithOAuth({
      provider: 'google',
      options: { skipBrowserRedirect: false },
    });
    if(!error) {
      <Navigate to='/home' replace />;
    }
      
    return { data, error };
  };

  const signOut = async () => {
    const { error } = await supa.auth.signOut();
    if (!error) {
      setUser(null);
      setSession(null);
    }
    return { error };
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {!loading ? children : `<div>Caricamento...</div>`}
    </AuthContext.Provider>
  );
};

export default AuthProvider;