import { useState, useEffect, useMemo } from "react";
import { supa } from "../components/firebase";
import { Plus, Minus, PageEdit } from 'iconoir-react'
import { useParams, useNavigate } from "react-router-dom";
import ModalConsulenza from "../components/modalConsulenza";
import ModalLezione from "../components/modalLezione";
import ModalInfoPacchetto from "../components/modalInfoPacchetto";
import ActivityIndicator from "../components/activityIndicator";

function ProfiloUtente() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [utente, setUtente] = useState({})
    const [quota, setQuota] = useState(null)

    const [modalConsulenza, setModalConsulenza] = useState(false)

    const [nome, setNome] = useState("")
    const [cognome, setCognome] = useState("")
    const [birth, setBirth] = useState(new Date())
    const [credito, setCredito] = useState(0)


    async function getUtente() {
        const { data, error } = await supa
            .from('utenti')
            .select('*')
            .eq('id', id)
        if (error) console.log("---", error)
        setUtente(data[0])
        setNome(data[0].nome)
        setCognome(data[0].cognome)
        setBirth(data[0].birth)
        setCredito(data[0].credito)
        setQuota(data[0]?.quota)
    }

    const [lezioni, setLezioni] = useState([])
    const [pacchettiAcquistati, setPacchettiAcquistati] = useState([])
    const [versamenti, setVersamenti] = useState([])

    const [lezioniEffettuate, setLezioniEffettuate] = useState([])

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedPacchettoPersonal, setSelectedPacchettoPersonal] = useState(null)
    const [modalLezione, setModalLezione] = useState(false)
    const [modalInfoPacchetto, setModalInfoPacchetto] = useState(false)

    const openModal = (users, pacchettoPersonal) => {
        setSelectedUser(users)
        setSelectedPacchettoPersonal(pacchettoPersonal)
        setModalLezione(true)
    }

    const openModalInfoPacchetto = (user, pacchetto) => {
        setSelectedUser(user)
        setSelectedPacchettoPersonal(pacchetto)
        setModalInfoPacchetto(true)
    }



    async function getLezioni() {
        const { data, error } = await supa
            .from('lezioni')
            .select('*,utenti(*),personalTrainer(*),pacchettiAcquistati(*,pacchetti(*))')
            .eq('id_utente', utente.id)
        if (error) console.log("---", error)
        data?.sort((a, b) => new Date(a.data) - new Date(b.data))
        setLezioni(data)
    }

    async function getPacchettiAcquistati() {
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .select('*,pacchetti(*)')
            .eq('id_utente', utente.id)
        if (error) console.log("---", error)
        data?.sort((a, b) => new Date(a.inizio) - new Date(b.inizio))
        setPacchettiAcquistati(data)

    }

    async function patchUtente() {
        const { data, error } = await supa
            .from('utenti')
            .update({ "nome": nome, "cognome": cognome, "birth": birth, "credito": credito, "quota": quota })
            .eq('id', utente.id)
        if (error) console.log(error)
        navigate(-1)
    }

    async function alertPatch() {
        if (window.confirm(`Sei sicuro di voler salvare le modifiche?`)) {
            checkQuota()
        }
    }

    async function checkQuota() {
        if (quota != utente?.quota) {
            if (window.confirm(`Hai modifcato la data di scadenza della quota, vuoi aggiungere anche un versamento per la quota?`)) {
                addVersamento()
            }
            else {patchUtente()}
           

        } else {
            patchUtente()
        }
    }

    async function addVersamento() {
        try{
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .insert({ "id_utente": utente?.id, "id_pacchetto": 18, "inizio": new Date(), "fine": new Date(), "totale": 40, "acconto": 40, "note": "Versamento automatico per modifica scadenza quota", "tipo": 2, completato: true })
            .select()
        if (error) console.log(",.,",error)
            const { data2, error2 } = await supa
            .from('versamenti')
            .insert({ "id_utente": utente?.id, "id_pacchettiAcquistati": data[0].id, "data": new Date(), "importo": 40 })
        if (error2) console.log(error2)
        patchUtente()
        } catch (error) {
            console.log("-------",error)
        }
    }

    async function getVersamenti() {
        const { data, error } = await supa
            .from('versamenti')
            .select('*,utenti(*),pacchettiAcquistati(*,pacchetti(*))')
            .eq('id_utente', utente.id)
        if (error) console.log("---", error)
        setVersamenti(data)
    }

    useEffect(() => {
        getUtente()

    }, [])

    useEffect(() => {
        utente?.id && getLezioni()
        utente?.id && getPacchettiAcquistati()
        utente?.id && getVersamenti()
    }
        , [utente.id])

    function refreshPage() {
        window.location.reload(false);
    }

    async function patchCheckPacchetto(id) {
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .update({ "completato": !pacchettiAcquistati.find((item) => item.id === id).completato })
            .eq('id', id)
        if (error) console.log(error);
        refreshPage()
    }

    function alertCheck(id) {
        if (window.confirm(`Sei sicuro di voler segnare come ${!pacchettiAcquistati.find((item) => item.id === id).completato ? "completato" : "non completato"}?`)) {
            patchCheckPacchetto(id)
        }
    }

    function alertDelete() {
        if (window.confirm(`Sei sicuro di voler eliminare il profilo?, \nATTENZIONE: questa azione è irreversibile`)) {
            deleteUtente()
        }
    }

    async function deleteUtente() {
        const { data, error } = await supa
            .from('utenti')
            .delete()
            .eq('id', utente.id)
        if (error) { alert("Errore durante l'eliminazione del profilo \nProbabilmente ha lezioni/pacchetti o versamenti collegati \nContattare GioPz se hai bisogno di assistenza") }
        else {
            navigate(-1)
        }
    }




    return (
        <div className="flex flex-1 h-[calc(100vh-4.5rem)] flex-col bg-zinc-100 overflow-scroll noBar items-center px-10 pb-10">
            {modalConsulenza && utente?.id && <ModalConsulenza visible={modalConsulenza} setVisible={setModalConsulenza} aggiorna={refreshPage} utente={utente} />}
            {modalLezione && <ModalLezione visible={modalLezione} setVisible={setModalLezione} aggiorna={refreshPage} utente={utente} pacchetto={selectedPacchettoPersonal} />}

            {modalInfoPacchetto && <ModalInfoPacchetto visible={modalInfoPacchetto} setVisible={setModalInfoPacchetto} aggiorna={refreshPage} utente={utente} pacchetto={selectedPacchettoPersonal} />}

            {!utente?.id ? <ActivityIndicator /> : <>
                <div className='w-full flex'>
                    <div className='flex flex-col h-full w-1/2'>
                        <div className='h-20 flex items-center justify-between'>
                            <div className='text-4xl text-zinc-800 font-bold'>Profilo Utente</div>
                            <div className='h-20 flex items-center justify-end'>

                                <div onClick={() => setModalConsulenza(true)} className="shadow-lg bg-yellow-400 -mr-2 cursor-pointer inline-flex items-center text-zinc-800 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
                                    + Aggiungi consulenza
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className='flex flex-col  h-full ml-10 w-1/2'>
                        <div className='h-20 flex items-center justify-between '>
                        <div className="-ml-4 flex w-1/2 items-center justify-between">
                                <p className="text-xl font-semibold">Quota Associativa</p>
                                <input type="date" onChange={(e) => setQuota(e.target.value)} value={quota} name="price" className={`${(quota != null && new Date(quota) > new Date()) ? "bg-green-50 border-green-300":"bg-red-500 border-red-300"} border  text-gray-900 text-sm rounded-xl focus:ring-primary-600 focus:border-primary-600 block w-40 p-2.5`} placeholder="Inserisci data di nascita" required="" />
                            </div>
                            <div onClick={() => alertDelete()} className="shadow-lg bg-red-600 cursor-pointer inline-flex items-center text-white font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
                                Elimina profilo
                            </div>
                        </div>

                    </div>


                </div>
                <form className="w-full">
                    <div className="grid gap-4 mb-4 h-full grid-cols-2">
                        <div className="col-span-1 ">
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                            <input type="text" onChange={(e) => setNome(e.target.value.toLocaleUpperCase())} value={nome} name="name" id="name" className="bg-white text-gray-900 text-sm rounded-xl shadow-sm shadow-zinc-200 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci nome" required="" />
                        </div>
                        <div className="col-span-1 ">
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cognome</label>
                            <input type="text" onChange={(e) => setCognome(e.target.value.toLocaleUpperCase())} value={cognome} name="name" id="name" className="bg-white text-gray-900 text-sm rounded-xl shadow-sm shadow-zinc-200 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci cognome" required="" />
                        </div>
                        <div className="col-span-1 ">
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data di nascita</label>
                            <input type="date" value={birth} onChange={(e) => setBirth(e.target.value)} name="price" id="price" className="bg-white text-gray-900 text-sm rounded-xl shadow-sm shadow-zinc-200 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />

                        </div>
                        <div className="col-span-1 ">
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Credito</label>
                            <input type="number" onChange={(e) => setCredito(e.target.value)} value={credito} name="name" id="name" className="bg-white text-gray-900 text-sm rounded-xl shadow-sm shadow-zinc-200 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Credito utente" required="" />
                        </div>
                        <div className="col-span-2 h-80">
                            <div className="flex justify-between">
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">Acquisti effettuati</label>
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">{pacchettiAcquistati?.length} </label>
                            </div>
                            <div className="bg-white shadow-lg  shadow-zinc-200 h-72 border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                <div className="overflow-hidden w-full h-full">

                                    <table className="text-left w-full h-full">
                                        <thead className="bg-white flex border-b border-yellow-400 text-gray-600 w-full">
                                            <tr className="flex w-full">
                                                <th className="p-4 text-zinc-800 w-2/4">Pacchetto</th>
                                                <th className="p-4 text-zinc-800 w-1/4">Inizio</th>
                                                <th className="p-4 text-zinc-800 w-1/4">Fine</th>
                                                <th className="p-4 text-zinc-800 w-1/5">Totale</th>
                                                <th className="p-4 text-zinc-800 w-1/5">Acconto</th>
                                                <th className="p-4 text-zinc-800 w-1/5">Rimanente</th>
                                                <th className="p-4 text-zinc-800 w-1/4">Sed. pacchetto</th>
                                                <th className="p-4 text-zinc-800 w-1/3">Sed. effettuate</th>
                                                <th className="p-4 text-zinc-800 w-1/5">Completato</th>
                                                <th className="p-4 text-zinc-800 w-1/5">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                            {pacchettiAcquistati?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                <td className="p-4 w-2/4">{item?.pacchetti?.nome}, {item?.totale} €</td>
                                                <td className="p-4 w-1/4">{item?.inizio ? new Date(item?.inizio).toLocaleDateString('it-IT') : ""}</td>
                                                <td className="p-4 w-1/4">{item?.fine ? new Date(item?.fine).toLocaleDateString('it-IT') : ""}</td>
                                                <td className="p-4 w-1/5">{item?.totale} €</td>
                                                <td className="p-4 w-1/5">{item?.acconto} €</td>
                                                <td className="p-4 w-1/5">{item?.totale - item?.acconto} €</td>
                                                <td className="p-4 w-1/4">{item?.pacchetti?.num_lezioni}</td>
                                                <td className={`p-4 w-1/3 flex items-start ${item?.tipo == 2 && "invisible"}`}>
                                                    <div className='flex w-1/2 items-center justify-between' >
                                                        {item?.sedute_effettuate}
                                                        <div onClick={() => openModal(item?.utenti, item)} className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center">
                                                            <Plus size={20} color='black' />
                                                        </div>
                                                        <div className="w-6 h-6 invisible cursor-pointer rounded-full bg-red-400 flex items-center justify-center">
                                                            <Minus size={20} color='white' />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`${item?.tipo == 2 && "invisible"} p-4 w-1/5`}>
                                                    <input type="checkbox" checked={item?.completato} onChange={() => alertCheck(item.id)} className='w-4 h-4 accent-yellow-400 cursor-pointer text-red-600 border-gray-300 rounded-xl focus:ring-yellow-500 focus:ring-2' />
                                                </td>
                                                <td className="p-4 w-1/5">
                                                    <PageEdit onClick={() => openModalInfoPacchetto(item?.utenti, item)} className=' cursor-pointer' size={20} color='black' />
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 h-64">
                            <div className="flex justify-between">
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">Lezioni effettuate </label>
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">{lezioni?.length} </label>
                            </div>
                            <div className="bg-white shadow-lg  shadow-zinc-200 h-4/5 border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                <div className="overflow-hidden w-full h-full">

                                    <table className="text-left w-full h-full">
                                        <thead className="bg-white flex border-b border-yellow-400 py-2 text-gray-600 w-full">
                                            <tr className="flex w-full">
                                                <th className="p-2 text-zinc-800 w-1/5">Data</th>
                                                <th className="p-2 text-zinc-800 w-2/4">Personal Trainer</th>
                                                <th className="p-2 text-zinc-800 w-3/4">Pacchetto</th>
                                                <th className="p-2 text-zinc-800 w-1/4">Tipo lezione</th>
                                            </tr>
                                        </thead>
                                        <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                            {lezioni?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                <td className="p-2 w-1/5">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                                                <td className="p-2 w-2/4">{item?.personalTrainer?.nome} {item?.personalTrainer?.cognome}</td>
                                                <td className="p-2 w-3/4">{item.pacchettiAcquistati?.pacchetti.nome}</td>
                                                <td className="p-2 w-1/4">{item?.pacchettiAcquistati?.tipo == "0" ? "personal" : "Pilates"}</td>

                                            </tr>)}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 h-64 ml-1">
                            <div className="flex justify-between">
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">Versamenti effettuati </label>
                                <label htmlFor="description" className="text-2xl font-semibold text-zinc-800 mb-2">{versamenti?.length} </label>
                            </div>
                            <div className="bg-white h-4/5 border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                <div className="overflow-hidden w-full h-full">

                                    <table className="text-left w-full h-full">
                                        <thead className="bg-white flex border-b border-yellow-400 py-2 text-gray-600 w-full">
                                            <tr className="flex w-full">
                                                <th className="p-2 text-zinc-800 w-1/5">Data</th>
                                                <th className="p-2 text-zinc-800 w-3/4">Pacchetto</th>
                                                <th className="p-2 text-zinc-800 w-1/4">Tipo</th>
                                                <th className="p-2 text-zinc-800 w-1/4">importo</th>
                                            </tr>
                                        </thead>
                                        <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                            {versamenti?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                <td className="p-2 w-1/5">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                                                <td className="p-2 w-3/4">{item.pacchettiAcquistati?.pacchetti.nome}</td>
                                                <td className="p-2 w-1/4">{item?.pacchettiAcquistati?.tipo == 0 ? "Pacchetto Personal" : item?.pacchettiAcquistati?.tipo == 1 ? "Pacchetto Pilates" : "Consulenza"}</td>
                                                <td className="p-2 w-1/4">{item?.importo} €</td>

                                            </tr>)}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    {(nome != utente.nome || cognome != utente?.cognome || birth != utente?.birth || credito != utente?.credito || quota != utente?.quota) && <div onClick={() => alertPatch()} className="shadow-lg bg-yellow-400 font-semibold absolute bottom-6 left-10 z-50 cursor-pointer inline-flex items-center text-zinc-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
                        Salva Modifiche
                    </div>}
                    {/*<div onClick={() => null} className="text-white cursor-pointer inline-flex items-center bg-red-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Elimina
    </div>*/}
                </form>
            </>}

        </div>
    );
}

export default ProfiloUtente;
