import { useState, useEffect } from "react";
import { supa } from "./firebase";
import { Trash } from 'iconoir-react'
import ActivityIndicator from "./activityIndicator";

function ModalInfoPacchetto({ setVisible, aggiorna, utente, pacchetto }) {
    const [notes, setNotes] = useState('');
    const [inizio, setInizio] = useState(new Date());
    const [fine, setFine] = useState(new Date());
    const [acconto, setAcconto] = useState(0);
    const [totale, setTotale] = useState(0);
    const [versamenti, setVersamenti] = useState([]);
    const [lezioniEffettuate, setLezioniEffettuate] = useState([]);
    const [loading, setLoading] = useState(false);  // Indica il caricamento generale
    const [isLoading, setIsLoading] = useState(false);  // Loading individuale per ciascuna funzione



    async function patchRow() {
        setIsLoading(true);
        const { error } = await supa
            .from('pacchettiAcquistati')
            .update({
                "inizio": inizio,
                "fine": fine,
                "acconto": parseInt(acconto) + parseInt(pacchetto.acconto),
                "note": notes,
                "totale": parseInt(totale)
            })
            .eq('id', pacchetto.id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        if (parseInt(acconto) > 0) {
            const { error: error2 } = await supa
                .from('versamenti')
                .insert({
                    "id_utente": utente.id,
                    "id_pacchettiAcquistati": pacchetto.id,
                    "importo": parseInt(acconto),
                    "data": new Date().toISOString().split('T')[0]
                });
            
            if (error2) console.log(error2);
        }

        aggiorna();
        setVisible(false);
        setIsLoading(false);
    }

    async function getVersamenti() {
        setIsLoading(true);
        const { data, error } = await supa
            .from('versamenti')
            .select('*')
            .eq('id_pacchettiAcquistati', pacchetto.id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        data.sort((a, b) => new Date(a.data) - new Date(b.data));
        setVersamenti(data);
        setIsLoading(false);
    }

    async function deleteVersamento(versamento) {
        setIsLoading(true);
        const { error } = await supa
            .from('versamenti')
            .delete()
            .eq('id', versamento.id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        const { error: error2 } = await supa
            .from('pacchettiAcquistati')
            .update({ "acconto": pacchetto.acconto - versamento.importo })
            .eq('id', pacchetto.id);

        if (error2) console.log(error2);

        aggiorna();
        getVersamenti();
        setIsLoading(false);
    }

    async function getLezioniEffettuate() {
        setIsLoading(true);
        const { data, error } = await supa
            .from('lezioni')
            .select('*, personalTrainer(*)')
            .eq('id_pacchetto_personal', pacchetto.id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        data.sort((a, b) => new Date(a.data) - new Date(b.data));
        setLezioniEffettuate(data);
        setIsLoading(false);
    }

    const deleteRow = async () => {
        setIsLoading(true);
        const { error } = await supa
            .from('pacchettiAcquistati')
            .delete()
            .eq('id', pacchetto.id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        aggiorna();
        setVisible(false);
        setIsLoading(false);
    };

    const deleteLezione = async (id) => {
        setIsLoading(true);
        const { error } = await supa
            .from('lezioni')
            .delete()
            .eq('id', id);

        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }

        const { error: error2 } = await supa
            .from('pacchettiAcquistati')
            .update({ "sedute_effettuate": pacchetto.sedute_effettuate - 1 })
            .eq('id', pacchetto.id);

        if (error2) console.log(error2);

        aggiorna();
        getLezioniEffettuate();
        setIsLoading(false);
    };

    const alertDelete = () => {
        if (window.confirm('Sei sicuro di voler eliminare questo pacchetto?')) {
            deleteRow();
        }
    };

    const alertDeleteVersamento = (versamento) => {
        if (window.confirm(`Sei sicuro di voler eliminare il versamento di ${versamento.importo} € del ${new Date(versamento.data).toLocaleDateString('it-IT')}?`)) {
            deleteVersamento(versamento);
        }
    };

    const alertModifica = () => {
        if (window.confirm('Sei sicuro di voler salvare le modifiche?')) {
            patchRow();
        }
    };

    const alertDeleteLezione = (lezione) => {
        if (window.confirm(`Sei sicuro di voler eliminare la lezione del ${new Date(lezione.data).toLocaleDateString('it-IT')} con ${lezione.personalTrainer.nome} ${lezione.personalTrainer.cognome}?`)) {
            deleteLezione(lezione.id);
        }
    };

    useEffect(() => {
        getLezioniEffettuate();
        setInizio(pacchetto.inizio);
        setFine(pacchetto.fine);
        setNotes(pacchetto.note);
        setTotale(pacchetto.totale);
        getVersamenti();
    }, []);

    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">
{isLoading && <ActivityIndicator />}
            <div className="relative p-4 w-full max-w-3xl max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Info Pacchetto
                        </h3>
                        <button onClick={() => setVisible(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5 overflow-y-auto  h-[40rem] flex flex-col">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-1 ">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Utente</label>
                                <div id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {utente.nome} {utente.cognome}
                                </div>
                            </div>
                            <div className="col-span-1 ">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pacchetto</label>
                                <div id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {pacchetto?.pacchetti?.nome}
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Inizio</label>
                                <input type="date" value={inizio} onChange={(e) => setInizio(e.target.value)} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Fine</label>
                                <input type="date" value={fine} onChange={(e) => setFine(e.target.value)} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Totale €</label>
                                <input type="number" onChange={(e) => setTotale(e.target.value)} value={totale} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Totale" required="" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Acconto</label>
                                <div id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {pacchetto?.acconto} €
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rimanente</label>
                                <div id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {pacchetto?.totale - pacchetto?.acconto - acconto} €
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Nuovo Acconto</label>
                                <input type="number" onChange={(e) => setAcconto(e.target.value)} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci nuovo acconto" required="" />
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Eventuali Note</label>
                                <textarea value={notes} onChange={(e) => setNotes(e.target.value)} name="description" id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5 " placeholder="Inserisci note" required=""></textarea>
                            </div>
                            <div className="col-span-2 h-44 overflow-y-auto">
                                <div className="flex justify-between">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Lezioni effettuate in questo pacchetto</label>
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">{lezioniEffettuate?.length} </label>
                                </div>
                                <div className="bg-gray-50 border h-4/5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                    <div className="overflow-hidden w-full h-full border">

                                        <table className="text-left w-full h-full">
                                            <thead className="bg-white flex border-b text-gray-600 w-full">
                                                <tr className="flex w-full bg-gray-100">
                                                    <th className="p-2 w-1/5">Numero</th>
                                                    <th className="p-2 w-2/4">Data</th>
                                                    <th className="p-2 w-2/4">Personal Trainer</th>
                                                    <th className="p-2 w-1/4">Azioni</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                                {lezioniEffettuate?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                    <td className="p-2 w-1/5">{index + 1}</td>
                                                    <td className="p-2 w-2/4">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                                                    <td className="p-2 w-2/4">{item?.personalTrainer?.nome} {item?.personalTrainer?.cognome}</td>
                                                    <td className="p-2 w-1/4">
                                                        <Trash onClick={() => alertDeleteLezione(item)} className=' cursor-pointer' size={20} color='black' />
                                                    </td>

                                                </tr>)}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>




                            <div className="col-span-2 h-44 ">
                            <div className="flex justify-between mt-4">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Versamenti effettuati per questo pacchetto</label>
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">{versamenti?.length} </label>
                                </div>
                                <div className="bg-gray-50 border h-4/5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                    <div className="overflow-hidden w-full h-full border">

                                        <table className="text-left w-full h-full">
                                            <thead className="bg-white flex border-b text-gray-600 w-full">
                                                <tr className="flex w-full bg-gray-100">
                                                    <th className="p-2 w-1/5">Numero</th>
                                                    <th className="p-2 w-2/4">Data</th>
                                                    <th className="p-2 w-2/4">Importo</th>
                                                    <th className="p-2 w-1/4">Azioni</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                                {versamenti?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                    <td className="p-2 w-1/5">{index + 1}</td>
                                                    <td className="p-2 w-2/4">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                                                    <td className="p-2 w-2/4">{item?.importo} €</td>
                                                    <td className="p-2 w-1/4">
                                                        <Trash onClick={() => alertDeleteVersamento(item)} className=' cursor-pointer' size={20} color='black' />
                                                    </td>

                                                </tr>)}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                </div>




                        </div>

                    </form>
                    <div className="flex w-full justify-between p-4 md:p-5">
                            <div onClick={() => alertModifica()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Salva Modifiche
                            </div>
                            <div onClick={() => alertDelete()} className="text-white cursor-pointer inline-flex items-center bg-red-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Elimina
                            </div>
                        </div>
                </div>
            </div>

        </div>
    );
}

export default ModalInfoPacchetto;
