import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supa } from '../components/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useAuth } from '../components/AuthProvider';

function Login() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }
  , [user]);

  return(
  <div className='flex w-screen bg-zinc-900 h-screen flex-row items-center justify-between'>
    <div className='w-3/5 h-full items-center shadow-xl justify-between flex flex-col'>
      <p className='invisible'>ciao</p>
    <div className="gas ne flex flex-column">
    <h2 className="symbol transform -scale-x-100 text-flicker-in-glow1">R</h2>
      <h2 className="symbol text-flicker-in-glow2">N</h2>
      <h2 className="symbol flex items-center text-flicker-in-glow3">P{/*<p className='symbol2 mt-40 -ml-[5.5rem]'>latform</p>*/}</h2>
    </div>
    <div className="gas ne focus-in-contract flex flex-column pb-2">
    <h2 className='symbol2 '>powered by GioPz</h2>
    </div>
    </div>
    <div className='w-2/5 h-full flex flex-col bg-[#FFE037] items-center justify-center'>
      <div className='w-4/5 h-4/5 flex flex-col items-center justify-center pt-20'>
    <div className='items-center w-3/5 justify-center mx-12'>
      <Auth
        supabaseClient={supa}

        appearance={{style:{button:{background:"rgb(24,24,27,1)", borderWidth:0, height:44, borderRadius:12, color:"#FFE037", marginTop:40},input:{height:44,borderRadius:12, borderWidth:0, background:"white", color:"black", padding:10},label:{color:"rgb(24,24,27,1)", paddingBottom:6}}}}
        providers={[]}
        
      />
   </div>
  </div>
  </div>
 
  
</div>);

}

export default Login;
