import { useState } from "react";
import { supa } from "./firebase";
import ActivityIndicator from "./activityIndicator";

function ModalSelezionaProdotto({ prodotti, setVisible, aggiorna, selectedData }) {
        const [selectedProdotti, setSelectedProdotti] = useState([])
        const [loading, setLoading] = useState(false)

        //function to add a rows in promise when resolve call aggiorna() and setVisible(false)
        async function addRow() {
            setLoading(true)
            const { data, error } = await supa
                .from('venditeProdotti')
                .insert(selectedProdotti.map(item => { return { "id_prodotto": item, "data": selectedData } }))
            if (error) console.log(error)
            setLoading(false)
            aggiorna()
            setVisible(false)

        }





    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">
{loading && <ActivityIndicator />}
            <div className="relative p-4 w-full max-w-5xl max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Aggiungi Prodotti
                        </h3>
                        <button onClick={() => setVisible(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="">
                        <div className='flex flex-col  h-96 w-full'>
                            <div className="overflow-hidden w-full h-full border pb-10">
                                <table className="text-left w-full h-full">
                                    <thead className="bg-white flex border-b text-gray-600 w-full">
                                        <tr className="flex w-full bg-gray-100">
                                            <th className="p-4 w-3/4">Prodotto Cognome</th>
                                            <th className="p-4 w-2/4">Costo</th>
                                            <th className="p-4 w-2/4">Prezzo Staff</th>
                                            <th className="p-4 w-2/4">Prezzo Clienti</th>
                                            <th className="p-4 w-2/4">Selezionato</th>
                                        </tr>
                                    </thead>
                                    <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                        {prodotti?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                            <td className="p-4 w-3/4">{item?.prodotto}</td>
                                            <td className="p-4 w-2/4">{item?.costo} €</td>
                                            <td className="p-4 w-2/4">{item?.prezzo_staff} €</td>
                                            <td className="p-4 w-2/4">{item?.prezzo_clienti} €</td>
                                            <td className="p-4 w-2/4"><input type="checkbox" onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedProdotti([...selectedProdotti, item.id])
                                                }
                                                else {
                                                    setSelectedProdotti(selectedProdotti.filter(i => i != item.id))
                                                }
                                            }
                                            } /></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div onClick={() => addRow()} className="text-white m-4 cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Aggiungi prodotti
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default ModalSelezionaProdotto;
