import { supa } from '../components/firebase';
import { useEffect, useState } from 'react';
import { Search, OpenInWindow } from 'iconoir-react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function Analisi() {
  const [loading, setLoading] = useState(false)
  const [dal, setDal] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0])
  const [al, setAl] = useState(new Date().toISOString().split('T')[0])
  const [lezioniPerPersonalTrainer, setLezioniPerPersonalTrainer] = useState({})
  const [versamenti, setVersamenti] = useState([])

  const [pacchettiAcquistati, setPacchettiAcquistati] = useState([])

  async function getLezioni() {
    //get all lezioni incluging personalTrainer between dal and al and group by personalTrainer
    setLoading(true)
    const { data, error } = await supa
      .from('lezioni')
      .select('*,personalTrainer(*)')
      .gte('data', dal)
      .lte('data', al)
    if (error) console.log(error)
    // group by personalTrainer.id
    const grouped = data.reduce((r, a) => {
      r[`${a.personalTrainer.nome} ${a.personalTrainer.cognome}`] = [...r[`${a.personalTrainer.nome} ${a.personalTrainer.cognome}`] || [], a];
      return r;
    }, {});

    setLezioniPerPersonalTrainer(grouped)
    setLoading(false)

    // new date seven days ago
    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

  }

  async function getPacchettiAcquistati() {
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .select('*,utenti(*)')
      .gte('created_at', dal)
      .lte('created_at', al)
    if (error) console.log(error)
      data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    setPacchettiAcquistati(data)
  }

  async function getVersamenti() {
    const { data, error } = await supa
      .from('versamenti')
      .select('*,utenti(*),pacchettiAcquistati(*,pacchetti(*))')
      .gte('data', dal)
      .lte('data', al)
    if (error) console.log(error)
      data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    setVersamenti(data)
  }


  useEffect(() => {
    getLezioni()
    getPacchettiAcquistati()
    getVersamenti()
  }
    , [])

    const refresh = () => {
      getLezioni()
      getPacchettiAcquistati()
      getVersamenti()
    }


  const data = {
    labels: lezioniPerPersonalTrainer && Object.keys(lezioniPerPersonalTrainer),
    datasets: [
      {
        label: 'Lezioni effettuate',
        data: lezioniPerPersonalTrainer && Object.keys(lezioniPerPersonalTrainer).map((key) => lezioniPerPersonalTrainer[key].length),
        backgroundColor: [
          '#519DE9',
          '#7CC674',
          '#73C5C5',
          '#8481DD',
          '#F6D173',
          '#EF9234',
          '#A30000',
          '#B8BBBE',
          '#519DE9',
          '#7CC674',
        ],
        borderColor: [
          '#519DE9',
          '#7CC674',
          '#73C5C5',
          '#8481DD',
          '#F6D173',
          '#EF9234',
          '#A30000',
          '#B8BBBE',
          '#519DE9',
          '#7CC674',
        ],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="flex flex-1 h-[calc(100vh-4.5rem)] flex-col items-center px-10 pb-10 bg-zinc-100 justify-center">
      <div className='h-3/6 w-full flex'>
        <div className='flex flex-col h-full w-1/2'>
          <div className='h-20 flex items-center justify-between'>
            <div className='text-3xl font-semibold'>Statistiche</div>


          </div>
          <div className='h-full flex items-center justify-between'>

            <div className='h-72 w-1/2  rounded-xl flex flex-col'>
              <Pie width={"100%"} height={"100%"} data={data} style={{}} options={{ responsive: true, maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
   }} />
            </div>
            <div className='h-full w-1/2  ml-10 rounded-3xl bg-white  shadow-lg  shadow-zinc-200'>
              <div className="overflow-hidden w-full h-full rounded-3xl ">

                <table className="text-left w-full h-full">
                  <thead className="bg-white flex w-full text-gray-600 border-b border-yellow-400">
                    <tr className="flex w-full bg-white">
                      <th className="p-4 w-3/4">Nome Cognome</th>
                      <th className="p-4 w-2/4">Altro</th>
                    </tr>
                  </thead>
                  <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                    {Object.keys(lezioniPerPersonalTrainer).map((item, index) => <tr key={index} className="flex w-full bg-white">
                      <td className="p-4 w-3/4">{item}</td>
                      <td className="p-4 w-1/4"></td>
                    </tr>)}
                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </div>


        <div className='flex flex-col  h-full ml-10 w-1/2'>
          <div className='h-20 flex items-center justify-between'>
            <div className='flex items-center'>
              <p className='mx-2'>Dal</p>
              <div className="col-span-2 sm:col-span-1">
                <input type="date" value={dal} onChange={(e) => setDal(e.target.value)} name="price" id="price" className="  inline-flex items-center text-zinc-800 bg-yellow-400 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center" placeholder="Inserisci data di nascita" required="" />
              </div>
              <p className='mx-2'>al</p>

              <div className="col-span-2 sm:col-span-1">
                <input type="date" value={al} onChange={(e) => setAl(e.target.value)} name="price" id="price" className="  inline-flex items-center text-zinc-800 bg-yellow-400 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center" placeholder="Inserisci data di nascita" required="" />
              </div>
            </div>
            <button onClick={() => refresh()} type="submit" className="  inline-flex items-center text-zinc-800 bg-yellow-400 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
              Invia
            </button>
          </div>
            <div className="overflow-hidden w-full h-full rounded-3xl  shadow-lg  shadow-zinc-200">
              <table className="text-left w-full h-full">
                <thead className="bg-white flex text-gray-600 border-b border-yellow-400 w-full">
                  <tr className="flex w-full bg-white">
                    <th className="p-4 w-2/4">Nome Cognome</th>
                    <th className="p-4 w-2/4">Lezioni Effettuate</th>
                    <th className="p-4 w-1/4">Guadagno</th>
                  </tr>
                </thead>
                <tbody className=" flex flex-col bg-white items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                  {Object.entries(lezioniPerPersonalTrainer).map((item, index) => <tr key={index} className="flex w-full bg-white">
                    <td className="p-4 w-2/4">{item[0]}</td>
                    <td className="p-4 w-2/4">{item[1].length}</td>
                    <td className="p-4 w-1/4">{item[1].length * item[1][0].personalTrainer.costo} €</td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>


      </div>
      <div className='h-20 w-full flex items-center justify-between pb-2'>
        <div className='w-1/2 flex items-center justify-between'>
          <div className='flex items-center text-2xl'>Pacchetti acquistati <p className='ml-2 font-bold text-yellow-400'>{pacchettiAcquistati?.length}</p></div>
          <div className='flex items-center text-2xl'>Totale: <p className='ml-2 font-bold text-yellow-400'>{pacchettiAcquistati?.reduce((acc, item) => acc + item.totale, 0)} €</p></div>
        </div>
        <div className='w-1/2 flex ml-10 items-center justify-between'>
        <div className='flex items-center text-2xl'>Incassi effettuati <p className='ml-2 font-bold text-yellow-400'>{versamenti?.length}</p></div>
        <div className='flex items-center text-2xl'>Totale incassato: <p className='ml-2 font-bold text-yellow-400'>{versamenti?.reduce((acc, item) => acc + item.importo, 0)} €</p></div>
        </div>
      </div>
      <div className='flex overflow-hidden w-full  h-3/6 p-2 pb-4'>
        <div className="overflow-hidden w-1/2 h-full bg-white shadow-lg  shadow-zinc-200 rounded-3xl">
              <table className="text-left w-full h-full">
                <thead className="bg-white flex text-gray-600 border-b border-yellow-400  w-full">
                  <tr className="flex w-full bg-white">
                    <th className="p-4 w-2/4">Nome Cognome</th>
                    <th className="p-4 w-2/4">Acquistato il</th>
                    <th className="p-4 w-1/4">tipo</th>
                    <th className="p-4 w-1/4">Importo</th>
                  </tr>
                </thead>
                <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                  {pacchettiAcquistati?.map((item, index) => <tr key={index} className="flex w-full bg-white">
                    <td className="p-4 w-2/4">{item?.utenti?.nome} {item?.utenti?.cognome}</td>
                    <td className="p-4 w-2/4">{item?.created_at ? new Date(item?.created_at).toLocaleDateString('it-IT') : ""}</td>
                    <td className="p-4 w-1/4">{item?.tipo==0?"Personal":item?.tipo==1?"Pilates":"Consulenza"}</td>
                    <td className="p-4 w-1/4">{item?.totale} €</td>
                  </tr>)}
                </tbody>
              </table>

        </div>
        <div className="overflow-hidden w-1/2 h-full ml-10 bg-white shadow-lg  shadow-zinc-200 rounded-3xl">
              <table className="text-left w-full h-full">
              <thead className="bg-white flex text-gray-600 border-b border-yellow-400  w-full">
              <tr className="flex w-full bg-white">
                    <th className="p-4 w-2/4">Nome Cognome</th>
                    <th className="p-4 w-2/4">Importo</th>
                    <th className="p-4 w-3/4">Pacchetto</th>
                    <th className="p-4 w-2/5">Importo Versato</th>
                  </tr>
                </thead>
                <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                  {versamenti?.map((item, index) => <tr key={index} className="flex w-full bg-white">
                    <td className="p-4 w-2/4">{item?.utenti?.nome} {item?.utenti?.cognome}</td>
                    <td className="p-4 w-2/4">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                    <td className="p-4 w-3/4">{item?.pacchettiAcquistati?.pacchetti?.nome}</td>
                    <td className="p-4 w-2/5">{item?.importo} €</td>
                  </tr>)}
                </tbody>
              </table>
            </div>

    
      </div>
    </div>
  );
}

export default Analisi;
