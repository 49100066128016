import './App.css';

function App() {


  return (
    <main className='flex'>
    <div className='w-full'>
    </div>
  </main>
  );
}

export default App;
