import { supa } from '../components/firebase';
import { useEffect, useState } from 'react';
import { Search, Plus, Minus, PageEdit } from 'iconoir-react';
import ModalPersonal from '../components/modalPersonal';
import ModalLezione from '../components/modalLezione';
import ModalInfoPacchetto from '../components/modalInfoPacchetto';
import ActivityIndicator from '../components/activityIndicator';

function Personal() {
  const [modalAdd, setModalAdd] = useState(false)
  const [modalLezione, setModalLezione] = useState(false)
  const [modalInfoPacchetto, setModalInfoPacchetto] = useState(false)

  const [loading, setLoading] = useState(true)

  const [pacchetti, setPacchetti] = useState([])
  const [search, setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedPachettoPersonal, setSelectedPacchettoPersonal] = useState(null)

  const [filter, setFilter] = useState(1)

  const openModal = (users, pacchettoPersonal) => {
    setSelectedUser(users)
    setSelectedPacchettoPersonal(pacchettoPersonal)
    setModalLezione(true)
  }

  const openModalInfoPacchetto = (user, pacchetto) => {
    setSelectedUser(user)
    setSelectedPacchettoPersonal(pacchetto)
    setModalInfoPacchetto(true)
  }


  async function getRows() {
    setLoading(true)
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .select('*,utenti(*),pacchetti(*)')
      .eq('tipo', 0)
    if (error) console.log("---", error); setLoading(false)
      data?.sort((a, b) => a.utenti.nome.localeCompare(b.utenti.nome) || a.utenti.cognome.localeCompare(b.utenti.cognome))
      setPacchetti(data)
      setLoading(false)
  }

  async function patchRow(id) {
    setLoading(true)
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .update({ "completato": !pacchetti.find((item) => item.id === id).completato })
      .eq('id', id)
    if (error) console.log(error); setLoading(false)
    getRows()
    setLoading(false)
  }

  function alertPatch(id) {
    if (window.confirm(`Sei sicuro di voler segnare come ${!pacchetti.find((item) => item.id === id).completato ? "completato" : "non completato"}?`)) {
      patchRow(id)
    }
  }


  useEffect(() => {
    getRows()
  }
    , [])

  return (
    <div className="flex flex-1 h-[calc(100vh-4.5rem)] flex-col bg-zinc-100 items-center px-10 pb-10 justify-center">
            {loading ? <ActivityIndicator /> : <>

      {modalAdd && <ModalPersonal visible={modalAdd} setVisible={setModalAdd} aggiorna={getRows} />}
      {modalLezione && <ModalLezione visible={modalLezione} setVisible={setModalLezione} aggiorna={getRows} utente={selectedUser} pacchetto={selectedPachettoPersonal} />}
      {modalInfoPacchetto && <ModalInfoPacchetto visible={modalInfoPacchetto} setVisible={setModalInfoPacchetto} aggiorna={getRows} utente={selectedUser} pacchetto={selectedPachettoPersonal} />}
      <div className='h-1/6 w-full pb-2'>
      <div className=' flex flex-row items-center justify-between'>
      <div className='text-4xl text-zinc-800 font-bold mt-4'>Lista Pacchetti Personal Acquistati</div>

      <button onClick={() => setModalAdd(true)} type="submit" className="shadow-lg text-zinc-800 inline-flex items-center bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center">
            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
            Aggiungi acquisto
          </button>
      </div>
        <div className='w-full h-1/2 flex items-center justify-between'>
          <div className='w-auto flex flex-row'>
            <div className='h-10 w-96 rounded-xl bg-white  shadow-zinc-200 px-2 flex items-center'>
              <Search size={20} color='black' />
              <input type='text' placeholder='Cerca utente' className='w-full h-full ml-2 bg-transparent outline-none' onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>
          <div className='w-auto bg-white h-10 flex flex-row ml-10 items-center rounded-xl'>
            <div onClick={()=> setFilter(0)} className={`w-36 ${filter==0 && "bg-yellow-400 font-semibold"} cursor-pointer h-5/6 m-1 flex items-center justify-center rounded-lg`}>Tutti ({pacchetti?.length})</div>
            <div onClick={()=> setFilter(3)} className={`w-36 ${filter==3 && "bg-red-300 font-semibold"} cursor-pointer h-5/6 m-1 flex items-center justify-center rounded-lg`}>In scadenza ({pacchetti?.filter(i=>new Date(i.fine) >= new Date() && new Date(i.fine) < new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000))?.length})</div>
            <div onClick={()=> setFilter(4)} className={`w-36 ${filter==4 && "bg-red-500 font-semibold"} cursor-pointer h-5/6 m-1 flex items-center justify-center rounded-lg`}>Scaduti ({pacchetti?.filter(i=>new Date(i.fine) <= new Date() && i.completato ==false)?.length})</div>
            <div onClick={()=> setFilter(1)} className={`w-44 ${filter==1 && "bg-yellow-400 font-semibold"} cursor-pointer h-5/6 m-1 flex items-center justify-center rounded-lg`}>Non completati ({pacchetti?.filter(i=>i?.completato===false)?.length})</div>
            <div onClick={()=> setFilter(2)} className={`w-36 ${filter==2 && "bg-gray-200 font-semibold"} cursor-pointer h-5/6 m-1 flex items-center justify-center rounded-lg`}>Completati ({pacchetti?.filter(i=>i?.completato===true)?.length})</div>
            </div>
          
        </div>


      </div>
      <div className='flex overflow-hidden w-full h-5/6'>
        <div className="overflow-hidden w-full h-full rounded-3xl">

          <table className="text-left w-full bg-white shadow-lg shadow-zinc-200 h-full">
            <thead className="bg-white py-2 flex border-b border-yellow-400 text-gray-600 w-full">
              <tr className="flex w-full">
                <th className="p-4 text-zinc-800 w-1/4">Nome</th>
                <th className="p-4 text-zinc-800 w-1/4">Cognome</th>
                <th className="p-4 text-zinc-800 w-1/4">Birth</th>
                <th className="p-4 text-zinc-800 w-2/4">Pacchetto</th>
                <th className="p-4 text-zinc-800 w-1/4">Inizio</th>
                <th className="p-4 text-zinc-800 w-1/4">Fine</th>
                <th className="p-4 text-zinc-800 w-1/5">Totale</th>
                <th className="p-4 text-zinc-800 w-1/5">Acconto</th>
                <th className="p-4 text-zinc-800 w-1/5">Rimanente</th>
                <th className="p-4 text-zinc-800 w-1/4">Sed. pacchetto</th>
                <th className="p-4 text-zinc-800 w-1/3">Sed. effettuate</th>
                <th className="p-4 text-zinc-800 w-1/5">Completato</th>
                <th className="p-4 text-zinc-800 w-1/5">Azioni</th>
              </tr>
            </thead>
            <tbody className=" flex flex-col mx-2 items-center overflow-y-scroll noBar pb-28 w-full h-full" >
              {pacchetti?.filter(i=>filter==2?i?.completato===true:filter==1?i?.completato==false:filter==3?(new Date(i.fine) >= new Date() && new Date(i.fine) < new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)):filter==4?(new Date(i.fine) <= new Date()) && i?.completato==false:i)?.filter((item) => (item?.utenti?.nome?.toLowerCase()?.includes(search?.toLowerCase()) || item?.utenti?.cognome?.toLowerCase()?.includes(search?.toLowerCase()))).map((item, index) => 
              <tr key={index} className="flex w-full hover:bg-zinc-100 py-1 bg-white">
                <td className="p-4 w-1/4">{item?.utenti?.nome}</td>
                <td className="p-4 w-1/4">{item?.utenti?.cognome}</td>
                <td className="p-4 w-1/4">{new Date(item?.utenti?.birth).toLocaleDateString('it-IT')}</td>                                                                                                                      
                <td className="p-4 w-2/4">{item?.pacchetti?.nome}</td>
                <td className="p-4 w-1/4">{item?.inizio ? new Date(item?.inizio).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/4">{item?.fine ? new Date(item?.fine).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/5">{item?.totale} €</td>
                <td className="p-4 w-1/5">{item?.acconto} €</td>
                <td className="p-4 w-1/5">{item?.totale - item?.acconto} €</td>
                <td className="p-4 w-1/4">{item?.pacchetti?.num_lezioni}</td>
                <td className="p-4 w-1/3 flex items-start">
                  <div className='flex w-1/2 items-center justify-between' >
                    <p className='w-12'>{item?.sedute_effettuate?item?.sedute_effettuate:0}</p>
                    <div onClick={()=> openModal(item?.utenti, item)} className="w-5 h-5 cursor-pointer rounded-full bg-yellow-400 flex items-center justify-center">
                      <Plus size={20} color='black' />
                    </div>
                    <div className="w-6 h-6 invisible cursor-pointer rounded-full bg-red-400 flex items-center justify-center">
                      <Minus size={20} color='white' />
                    </div>
                  </div>
                </td>
                <td className="p-4 w-1/5">
                  <input type="checkbox" checked={item?.completato} onChange={() => alertPatch(item.id)}  className='w-4 h-4 accent-yellow-400 cursor-pointer text-red-600 border-gray-300 rounded-xl focus:ring-yellow-500 focus:ring-2'/>
                </td>
                <td className="p-4 w-1/5">
                    <PageEdit onClick={()=> openModalInfoPacchetto(item?.utenti, item)} className=' cursor-pointer' size={20} color='black' />
                </td>
              </tr>)}
            </tbody>
          </table>

        </div>
      </div>
      </>}
    </div>
  );
}

export default Personal;
