import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet, Navigate} from 'react-router-dom';
import './index.css';
import Login from './pages/Login.js';
import ProtectedPage from './pages/ProtectedPage.js';
import AuthProvider from './components/AuthProvider.js';
import App from './App.js';
import Header from './components/header.js';
import Personal from './pages/Personal.js';
import Pilates from './pages/Pilates.js';
import Home from './pages/Home.js';
import Statistiche from './pages/Statistiche.js';
import ProfiloUtente from './pages/ProfiloUtente.js';
import Bar from './pages/Bar.js';


export default function Root() {
  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  );
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Navigate to='/login' replace />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: '/',
        element: <ProtectedPage />,
        children: [
          {
            path: 'personal',
            element: <Personal />,
          },
          {
            path: 'pilates',
            element: <Pilates />,
          },
          {
            path: 'home',
            element: <Home />,
          },
          {
            path: 'statistiche',
            element: <Statistiche />,
          },
          {
            path: 'profiloutente/:id',
            element: <ProfiloUtente />,
          },
          {
            path: 'bar',
            element: <Bar />,
          },
        ],
      },
      
    ],
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

