import { Navigate, Outlet } from 'react-router-dom';
import Header from '../components/header';
import { useAuth } from '../components/AuthProvider';

const ProtectedPage = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to='/login' replace />;
  }

  return     <>
  <main>
    <Header />
    <Outlet />
  </main>
</>;
};

export default ProtectedPage;