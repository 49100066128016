import { useState, useEffect } from "react";
import { supa } from "./firebase";
import  ActivityIndicator  from "./activityIndicator";

function ModalLezione({ utente, setVisible, aggiorna, pacchetto }) {
    const [notes, setNotes] = useState('');
    const [personalTrainers, setPersonalTrainers] = useState([]);
    const [datas, setData] = useState(new Date().toISOString().split('T')[0]);
    const [selectedPersonalTrainer, setSelectedPersonalTrainer] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Stato per indicatore di caricamento


    async function addRow() {
        if (!datas || !selectedPersonalTrainer) return alert('Inserisci tutti i campi');
        setIsLoading(true);
        const { data, error } = await supa
            .from('lezioni')
            .insert({
                "id_utente": utente.id,
                "id_personalTrainer": selectedPersonalTrainer,
                "data": datas,
                "note": notes,
                "id_pacchetto_personal": pacchetto.id
            });
        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }
        await patchPacchetto();
        setIsLoading(false);
    }

    async function patchPacchetto() {
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .update({ "sedute_effettuate": pacchetto.sedute_effettuate + 1 })
            .eq('id', pacchetto.id);
        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }
        aggiorna();
        setVisible(false);
    }

    async function getPersonalTrainers() {
        setIsLoading(true);
        const { data, error } = await supa
            .from('personalTrainer')
            .select('*');
        if (error) {
            console.log(error);
            setIsLoading(false);
            return;
        }
        if (data.length > 0) setSelectedPersonalTrainer(data[0].id);
        setPersonalTrainers(data);
        setIsLoading(false);
    }

    useEffect(() => {
        getPersonalTrainers();
    }, []);

    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">
            {isLoading && <ActivityIndicator />}
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Nuova Lezione
                        </h3>
                        <button onClick={() => setVisible(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Utente</label>
                                <div id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {utente.nome} {utente.cognome}
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Personal Trainer</label>
                                <select id="category" onChange={(e) => { setSelectedPersonalTrainer(e.target.value) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    {personalTrainers.map((item, index) => (
                                        <option key={index} value={item.id}>{item.nome} {item.cognome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Data</label>
                                <input type="date" value={datas} onChange={(e) => setData(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Eventuali Note</label>
                                <textarea onChange={(e) => setNotes(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5" placeholder="Inserisci note" />
                            </div>
                        </div>
                        <div onClick={() => addRow()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Aggiungi lezione
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModalLezione;
