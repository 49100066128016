import { supa } from '../components/firebase';
import { useEffect, useState } from 'react';
import { Search, OpenInWindow } from 'iconoir-react';
import ModalUser from '../components/modalUser';
import ModalPacchetto from '../components/modalPacchetto';
import ModalPersonalTrainer from '../components/modalPersonalTrainer';
import ModalInfoUser from '../components/modalInfoUser';
import ModalInfoPersonalTrainer from '../components/modalInfoPersonalTrainer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import ActivityIndicator from '../components/activityIndicator';

function Home() {
  const { user } = useAuth();
  const navigate = useNavigate()
  const [modalAdd, setModalAdd] = useState(false)
  const [modalAddPacchetto, setModalAddPacchetto] = useState(false)
  const [modalAddPersonalTrainer, setModalAddPersonalTrainer] = useState(false)

  const [loading, setLoading] = useState(false)

  const [modalInfoUser, setModalInfoUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [modalInfoPersonalTrainer, setModalInfoPersonalTrainer] = useState(false)
  const [selectedPersonalTrainer, setSelectedPersonalTrainer] = useState(null)

  const [usentiAttivi, setUtentiAttivi] = useState(0)
  const [utentiInScadenza, setUtentiInScadenza] = useState(0)

  const [users, setUsers] = useState([])
  const [pacchetti, setPacchetti] = useState([])
  const [personalTrainers, setPersonalTrainers] = useState([])
  const [search, setSearch] = useState('')

  async function getUtenti() {
    setLoading(true)
    const { data, error } = await supa
      .from('utenti')
      .select('*')
    if (error) console.log(error); setLoading(false)
      data.sort((a, b) => a.nome.localeCompare(b.nome) || a.cognome.localeCompare(b.cognome))
      setUsers(data)
      setLoading(false)
  }

  async function getPacchetti() {
    setLoading(true)
    const { data, error } = await supa
      .from('pacchetti')
      .select('*')
    if (error) console.log(error); setLoading(false)
    setPacchetti(data)
    setLoading(false)
  }

  async function getPersonalTrainers() {
    setLoading(true)
    const { data, error } = await supa
      .from('personalTrainer')
      .select('*')
    if (error) console.log(error); setLoading(false)
    setPersonalTrainers(data?.sort((a, b) => a.nome.localeCompare(b.nome) || a.cognome.localeCompare(b.cognome)))
    setLoading(false)
  }

  async function personalInScadenza() {
    //select * from personal where fine < now() + 7 days, fine is like a date yyyy-mm-dd
    setLoading(true)
    const { data, error } = await supa
      .from('pacchettiAcquistati')
      .select('*')
      //.gt('fine', new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0])
    if (error) console.log(error); setLoading(false)
      //setUtentiAttivi where fine < now()
      setUtentiAttivi(data.filter((item) => new Date(item.fine) >= new Date()).length)
      setUtentiInScadenza(data.filter((item) =>  (new Date(item.fine) >= new Date() && new Date(item.fine) < new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000))).length)
      

      setLoading(false)

  }
console.log(usentiAttivi  )
  useEffect(() => {
    getUtenti()
    getPacchetti()
    getPersonalTrainers()
    personalInScadenza()
  }
    , [])

    //console.log("Perchè sei qui? \nnon fare danni! \nchiudi e vai via\n\n\n     GioPz")

    const openModalInfoUser = (user) => {
      setSelectedUser(user)
      setModalInfoUser(true)
    }

    const openModalInfoPersonalTrainer = (personalTrainer) => {
      setSelectedPersonalTrainer(personalTrainer)
      setModalInfoPersonalTrainer(true)
    }

    console.log(users.length)

  return (
    <div className="flex flex-1 h-[calc(100vh-4.5rem)] bg-zinc-100 flex-col items-center px-10 pb-10 justify-center">
            {loading ? <ActivityIndicator/> : <>

      {modalAdd && <ModalUser visible={modalAdd} setVisible={setModalAdd} aggiorna={getUtenti} />}
      {modalAddPacchetto && <ModalPacchetto visible={modalAddPacchetto} setVisible={setModalAddPacchetto} aggiorna={getPacchetti} />}
      {modalAddPersonalTrainer && <ModalPersonalTrainer visible={modalAddPersonalTrainer} setVisible={setModalAddPersonalTrainer} aggiorna={getPersonalTrainers} />}
      {modalInfoUser && <ModalInfoUser visible={modalInfoUser} setVisible={setModalInfoUser} utente={selectedUser} aggiorna={getUtenti} />}
      {selectedPersonalTrainer && modalInfoPersonalTrainer && <ModalInfoPersonalTrainer visible={modalInfoPersonalTrainer} setVisible={setModalInfoPersonalTrainer} personalTrainer={selectedPersonalTrainer} aggiorna={getPersonalTrainers} />}
      <div className='h-2/6 w-full flex'>
        <div className='flex flex-col h-full w-1/2'>
          <div className='h-20 flex items-center justify-between'>
            <div className='text-4xl text-zinc-800 font-bold'>Dashboard</div>
            <button onClick={() => setModalAddPersonalTrainer(true)} type="submit" className=" bg-yellow-400 text-zinc-800 font-semibold inline-flex items-center focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
              <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
              Aggiungi personal trainer
            </button>
          </div>
          <div className='h-full flex items-center justify-between'>

            <div className='h-full w-1/2 rounded-3xl bg-zinc-800 shadow-lg justify-between shadow-zinc-200 pt-4 flex flex-col'>
            <div className='items-center pr-10 justify-end w-full flex text-3xl text-yellow-400 font-bold'><p className=' text-white text-xl mr-2'>Info</p>Pacchetti</div>

              {/*<div className='h-1/2 w-full items-center flex ml-8 mt-4 text-2xl font-semibold text-white'>Pacchetti Disponibili</div>
              <div className='h-1/2 w-full items-center flex ml-8 mb-4 text-5xl text-yellow-400 font-bold'>{pacchetti?.length}</div>*/}
              <div className='flex flex-row items-center px-10 justify-between'>
                <div className='w-1/4 items-center flex text-5xl text-yellow-400 font-bold'>{usentiAttivi}</div>
              <div className='w-full items-end justify-end flex text-5xl font-semibold text-white'>Attivi</div>
              </div>
              <div className='flex flex-row items-center px-10 pb-5 ml-3 justify-between'>
                <div className='w-1/4 items-center flex text-3xl text-yellow-400 font-bold'>{utentiInScadenza}</div>
              <div className='w-full items-end justify-end flex text-2xl font-semibold text-white'>In Scadenza</div>
              </div>
            </div>
            <div className='h-full w-1/2  ml-10  rounded-3xl bg-white'>
              
              <div className="overflow-hidden w-full h-full rounded-2xl  shadow-lg  shadow-zinc-200">

                <table className="text-left w-full h-full">
                  <thead className="bg-white flex border-b border-yellow-400 text-gray-600 w-full">
                    <tr className="flex w-full ">
                      <th className="p-4 text-zinc-800 w-2/4">Nome</th>
                      <th className="p-4 text-zinc-800 w-1/4">Cognome</th>
                      <th className="p-4 text-zinc-800 w-1/4">Azioni</th>
                    </tr>
                  </thead>
                  <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                    {personalTrainers?.map((item, index) => <tr key={index} className="flex w-full bg-white">
                      <td className="p-4 w-2/4">{item?.nome}</td>
                      <td className="p-4 w-1/4">{item?.cognome}</td>
                      <td className="p-4 w-1/6 ml-3">
                        <OpenInWindow size={20} color='black' className='text-yellow-400 cursor-pointer' onClick={() => { openModalInfoPersonalTrainer(item) }} />
                      </td>
                    </tr>)}
                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </div>


        <div className='flex flex-col  h-full ml-10 w-1/2'>
          <div className='h-20 flex items-center justify-between'>
            <div className='text-2xl font-semibold text-zinc-800 '>Lista pacchetti</div>
            <button onClick={() => setModalAddPacchetto(true)} type="submit" className="  inline-flex items-center bg-yellow-400 text-zinc-800 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
              <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
              Aggiungi pacchetto
            </button>
          </div>
          <div className='h-full flex items-center justify-between'>
            <div className="overflow-hidden w-full h-full shadow-lg  shadow-zinc-200 rounded-3xl">

              <table className="text-left w-full h-full">
                <thead className="bg-white flex border-b border-yellow-400 text-gray-600 w-full">
                  <tr className="flex w-full">
                    <th className="p-4 text-zinc-800 w-2/4">Nome</th>
                    <th className="p-4 text-zinc-800 w-1/4">Prezzo</th>
                    <th className="p-4 text-zinc-800 w-1/4">Numero lezioni</th>
                  </tr>
                </thead>
                <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                  {pacchetti?.map((item, index) => <tr key={index} className="flex w-full bg-white">
                    <td className="p-4 w-2/4">{item?.nome}</td>
                    <td className="p-4 w-1/4">{item?.prezzo} €</td>
                    <td className="p-4 w-1/4">{item?.num_lezioni}</td>

                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>
      <div className='h-1/6 w-full pb-2'>
        <div className='w-full h-1/2 flex items-center font-semibold text-zinc-800 text-2xl'>Lista Utenti</div>
        <div className='w-full h-1/2 flex items-center justify-between'>
          <div>
            <div className='h-10 w-96 rounded-xl bg-white  shadow-zinc-200 px-2 flex items-center'>
              <Search size={20} color='black' />
              <input type='text' placeholder='Cerca utente' className='w-full h-full ml-2 bg-transparent outline-none' onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>
          <button onClick={() => setModalAdd(true)} type="submit" className="  inline-flex items-center text-zinc-800 bg-yellow-400 font-semibold focus:ring-4 focus:outline-none focus:ring-yellow-300 rounded-xl text-sm px-5 py-2.5 text-center">
            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
            Aggiungi utente
          </button>
        </div>


      </div>
      <div className='flex overflow-hidden w-full  shadow-lg  shadow-zinc-200 h-3/6'>
        <div className="overflow-hidden w-full h-full rounded-3xl">

          <table className="text-left w-full h-full">
            <thead className="bg-white flex text-gray-600 border-b border-yellow-400 w-full">
              <tr className="flex w-full py-[0.3rem]">
                <th className="p-4 text-zinc-800 font-bold w-1/4">Nome</th>
                <th className="p-4 text-zinc-800 font-bold w-1/4">Cognome</th>
                <th className="p-4 text-zinc-800 font-bold w-1/4">Data di nascita</th>
                <th className="p-4 text-zinc-800 font-bold w-1/4">Note</th>
                <th className="p-4 text-zinc-800 font-bold w-1/4">Aggiunto il</th>
                <th className="p-4 text-zinc-800 font-bold w-1/4">Quota</th>
                <th className="p-4 text-zinc-800 font-bold w-1/12">Azioni</th>
              </tr>
            </thead>
            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
              {users?.filter((item) => item?.nome?.toLowerCase()?.includes(search?.toLowerCase()) || item?.cognome?.toLowerCase()?.includes(search?.toLowerCase())).map((item, index) => 
              <tr key={index} className="flex w-full hover:bg-zinc-100 bg-white">
                <td className="p-4 w-1/4">{item?.nome}</td>
                <td className="p-4 w-1/4">{item?.cognome}</td>
                <td className="p-4 w-1/4">{item?.created_at ? new Date(item?.birth).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/4">{item?.note}</td>
                <td className="p-4 w-1/4">{item?.created_at ? new Date(item?.created_at).toLocaleDateString('it-IT') : ""}</td>
                <td className="p-4 w-1/4"><div className={`h-5 w-5 rounded-3xl  ${(item?.quota != null && new Date(item?.quota) > new Date()) ?"bg-green-500":"bg-red-500"}`}></div></td>
                <td className="p-4 w-1/12">
                  <OpenInWindow size={20} color='black' className=' cursor-pointer ml-2 text-yellow-400' onClick={() => navigate(`/profiloUtente/${item.id}`)} />
                </td>

              </tr>)}
            </tbody>
          </table>

        </div>
      </div>
      </>}
    </div>
  );
}

export default Home;
