import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useState, useEffect } from "react";

// Produzione
const supabase = createClient("https://etgygotpvhqlatkeofgl.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV0Z3lnb3RwdmhxbGF0a2VvZmdsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTY5OTM4MDMsImV4cCI6MjAzMjU2OTgwM30.stM-0zvVhJRwBW2Wj2Y_k_OGDB4T5r1E9qnAU8508Nc");

// Test
//const supabase = createClient("https://axapnzbmksoqispkhniz.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF4YXBuemJta3NvcWlzcGtobml6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTMzMDg0MTksImV4cCI6MTk2ODg4NDQxOX0.9obYsggjKevW9Z0Z3zYFAzcJT0W8a9FG5XeBT7LfaNo");


export default function AppSupa() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />)
  }
  else {
    return (<div>Logged in!</div>)
  }
}
export const supa = supabase;


