import { useState, useEffect } from "react";
import { supa } from "./firebase";
import { Trash } from 'iconoir-react'

function ModalInfoUser({ setVisible, aggiorna, utente }) {
    const [nome, setNome] = useState(utente.nome)
    const [cognome, setCognome] = useState(utente.cognome)
    const [birth, setBirth] = useState(utente.birth)

    const [lezioni, setLezioni] = useState([])
    const [pacchettiAcquistati, setPacchettiAcquistati] = useState([])

    const [lezioniEffettuate, setLezioniEffettuate] = useState([])


    async function getLezioni() {
        const { data, error } = await supa
            .from('lezioni')
            .select('*,utenti(*),personalTrainer(*),pacchettiAcquistati(*,pacchetti(*))')
            .eq('id_utente', utente.id)
        if (error) console.log("---", error)
        data.sort((a, b) => new Date(a.data) - new Date(b.data))
        setLezioni(data)
    }

    async function getPacchettiAcquistati() {
        const { data, error } = await supa
            .from('pacchettiAcquistati')
            .select('*,pacchetti(*)')
            .eq('id_utente', utente.id)
        if (error) console.log("---", error)
            data.sort((a, b) => new Date(a.inizio) - new Date(b.inizio))
        setPacchettiAcquistati(data)
    }

    async function patchUtente() {
        const { data, error } = await supa
            .from('utenti')
            .update({ "nome": nome, "cognome": cognome, "birth": birth })
            .eq('id', utente.id)
        if (error) console.log(error)
        aggiorna()
        setVisible(false)
    }

    async function alertPatch() {
        if (window.confirm(`Sei sicuro di voler salvare le modifiche?`)) {
            patchUtente()
        }
    }


    useEffect(() => {
        getLezioni()
        getPacchettiAcquistati()
    }, [])


    return (
        <div className="flex h-full w-full top-0 left-0 items-center justify-center fixed backdrop-blur-lg backdrop-brightness-75">

            <div className="relative p-4 w-full max-w-7xl max-h-full">
                <div className="relative bg-white rounded-xl shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900">
                            Info Utente
                        </h3>
                        <button onClick={() => setVisible(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-1 ">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                                <input type="text" onChange={(e) => setNome(e.target.value.toLocaleUpperCase())} value={nome} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci nome" required="" />
                            </div>
                            <div className="col-span-1 ">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cognome</label>
                                <input type="text" onChange={(e) => setCognome(e.target.value.toLocaleUpperCase())} value={cognome} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci cognome" required="" />
                            </div>
                            <div className="col-span-1 ">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data di nascita</label>
                                <input type="date" value={birth} onChange={(e) => setBirth(e.target.value)} name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Inserisci data di nascita" required="" />

                            </div>
                            <div className="col-span-2 h-52">
                                <div className="flex justify-between">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Pacchetti acquistati </label>
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">{pacchettiAcquistati?.length} </label>
                                </div>
                                <div className="bg-gray-50 border h-4/5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                    <div className="overflow-hidden w-full h-full border">

                                        <table className="text-left w-full h-full">
                                            <thead className="bg-white flex border-b text-gray-600 w-full">
                                                <tr className="flex w-full bg-gray-100">
                                                    <th className="p-4 w-2/4">Pacchetto</th>
                                                    <th className="p-4 w-1/4">Inizio</th>
                                                    <th className="p-4 w-1/4">Fine</th>
                                                    <th className="p-4 w-1/5">Totale</th>
                                                    <th className="p-4 w-1/5">Acconto</th>
                                                    <th className="p-4 w-1/5">Rimanente</th>
                                                    <th className="p-4 w-1/4">Sed. pacchetto</th>
                                                    <th className="p-4 w-1/3">Sed. effettuate</th>
                                                    <th className="p-4 w-1/5">Completato</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                                {pacchettiAcquistati?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                    <td className="p-4 w-2/4">{item?.pacchetti?.nome}, {item?.totale} €</td>
                                                    <td className="p-4 w-1/4">{item?.inizio ? new Date(item?.inizio).toLocaleDateString('it-IT') : ""}</td>
                                                    <td className="p-4 w-1/4">{item?.fine ? new Date(item?.fine).toLocaleDateString('it-IT') : ""}</td>
                                                    <td className="p-4 w-1/5">{item?.totale} €</td>
                                                    <td className="p-4 w-1/5">{item?.acconto} €</td>
                                                    <td className="p-4 w-1/5">{item?.totale - item?.acconto} €</td>
                                                    <td className="p-4 w-1/4">{item?.pacchetti?.num_lezioni}</td>
                                                    <td className="p-4 w-1/3 flex items-start">
                                                        <div className='flex w-1/2 items-center justify-between' >
                                                            {item?.sedute_effettuate}

                                                        </div>
                                                    </td>
                                                    <td className="p-4 w-1/5">
                                                        <input type="checkbox" checked={item?.completato} contentEditable="false"  className='w-4 h-4 cursor-default accent-yellow-400 text-red-600 border-gray-300 rounded-xl focus:ring-yellow-500 focus:ring-2' />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 h-64">
                                <div className="flex justify-between">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Lezioni effettuate </label>
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">{lezioni?.length} </label>
                                </div>
                                <div className="bg-gray-50 border h-4/5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full overflow-hidden">
                                    <div className="overflow-hidden w-full h-full border">

                                        <table className="text-left w-full h-full">
                                            <thead className="bg-white flex border-b text-gray-600 w-full">
                                                <tr className="flex w-full bg-gray-100">
                                                    <th className="p-2 w-1/5">Data</th>
                                                    <th className="p-2 w-2/4">Personal Trainer</th>
                                                    <th className="p-2 w-3/4">Pacchetto</th>
                                                    <th className="p-2 w-1/4">Tipo lezione</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" flex flex-col items-center overflow-y-scroll noBar pb-14 w-full h-full" >
                                                {lezioni?.map((item, index) => <tr key={index} className="flex w-full bg-white border-b">
                                                    <td className="p-2 w-1/5">{item?.data ? new Date(item?.data).toLocaleDateString('it-IT') : ""}</td>
                                                    <td className="p-2 w-2/4">{item?.personalTrainer?.nome} {item?.personalTrainer?.cognome}</td>
                                                    <td className="p-2 w-3/4">{item.pacchettiAcquistati?.pacchetti.nome}</td>
                                                    <td className="p-2 w-1/4">{item?.pacchettiAcquistati?.tipo == "0" ? "personal" : "Pilates"}</td>

                                                </tr>)}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-between">
                            <div onClick={() => alertPatch()} className="text-white cursor-pointer inline-flex items-center bg-black focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Salva Modifiche
                            </div>
                            {/*<div onClick={() => null} className="text-white cursor-pointer inline-flex items-center bg-red-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Elimina
    </div>*/}
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default ModalInfoUser;
